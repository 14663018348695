import PageHeader from './components/PageHeader'
import PageCarousel from './components/PageCarousel'
import PageBus from './components/PageBus'

export default function App() {
  return (
    <div>
      <PageHeader />
      <PageCarousel />
      <PageBus />
    </div>
  );
}
