import styled from 'styled-components'

export const StyledPageHeaderWrapper = styled.div`
  height: 80px;
  line-height: 80px;
  /* border: 1px solid #000; */
  .contact-us {
    text-align: right;
    .btn-outline-primary {
      font-size: 16px;
      font-weight: 600;
      @media (max-width: 323px) {
        font-size: 14px;
      }

    }
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
      color: #FFF !important;
    }
  }
  
`