import styled from 'styled-components'

export const StyledCarouselWrapper = styled.div`
  text-align: center;
  min-height: 195px;
  margin-top: 50px;
  margin-bottom: 75px;

  
  @media (max-width: 576px) {
    margin-bottom: 29px;
  }

  .carousel-item {
    min-height: 166px;
    div.row {
      margin: 0 auto;
      max-width: 540px;
    }
  }

  .carousel-indicators {
    bottom: -29px;
    li {
      background-color: #DFE3E8;
      opacity: 1;
    }
    .active {
      background-color: #00D8A5;
    }
  }
`