import React from 'react'
import BukoBusIcon from '../../assets/images/bukobus.png'
import { StyledPageHeaderWrapper } from './styles'
import { Row, Col, Container, Button } from 'react-bootstrap'


export default function PageHeader() {
  const mailTo = () => {
    window.location.href = "mailto:hello@bukobus.com"
  }
  return (
    <StyledPageHeaderWrapper>
      <Container fluid>
        <Row>
          <Col className='branding'><img src={BukoBusIcon} alt="Bukobus" /></Col>
          <Col className='contact-us'>
            <Button variant="outline-primary" onClick={() => mailTo()}>Contact Us</Button>
          </Col>
        </Row>
      </Container>
    </StyledPageHeaderWrapper>
  )
}
