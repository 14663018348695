import React from 'react'
import { StyledCarouselWrapper } from './styles'
import { Row, Col, Carousel } from 'react-bootstrap'

export default function PageHeader() {
  return (
    <StyledCarouselWrapper>
      <Carousel>
        <Carousel.Item interval={100000}>
          <Row>
            <Col>
              <h1>Bringing travel to the 21st century</h1>
              <p>We empower transport operators by streamlining fleet management and fare collection.</p>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <h1>In the business of moving people?</h1>
              <p>We’ve got you covered. Let's talk at hello@bukobus.com</p>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <h1>Go anywhere, anytime</h1>
              <p>Book a trip in just a few clicks.</p>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col>
              <h1>Making payments faster and easier</h1>
              <p>With BukoPay, we simplify transactions by making everything seamless end-to-end from users to operators.</p>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </StyledCarouselWrapper>
  )
}
