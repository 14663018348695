import React from 'react'
import HeroImage from '../../assets/images/hero-image.png'
import HeroImageMobile from '../../assets/images/hero-image-mobile.png'
import { StyledPageBusWrapper } from './styles'


export default function PageHeader() {
  return (
    <StyledPageBusWrapper>
      <img src={HeroImage} alt="Bukobus" className='d-none d-sm-block' />
      <img src={HeroImageMobile} alt="Bukobus" className='d-block d-sm-none' style={{width: '100%'}}/>
    </StyledPageBusWrapper>
  )
}
